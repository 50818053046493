<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login -->
      <b-card class="mb-0">
        <image-logo />

        <b-card-title class="mb-2 text-center">
          Crie sua nova senha
        </b-card-title>
        <b-card-text class="mb-2 text-center">
          Preencha os campos abaixo:
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="registerName"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >

            <!-- password -->
            <b-form-group
              label-for="register-password"
              label="Senha"
            >
              <validation-provider
                #default="{ errors }"
                ref="register"
                name="senha"
                vid="confirm"
                rules="required|check"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="register-password"
                    v-model="password"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false:null"
                    name="register-password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- confirm password -->
            <b-form-group
              label-for="register-confirm-password"
              label="Confirmar Senha"
            >
              <validation-provider
                #default="{ errors }"
                name="confirmar senha"
                rules="required|confirmed:confirm"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="register-confirm-password"
                    v-model="confirmPassword"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false:null"
                    name="register-confirm-password"
                    data-vv-as="password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <div class="text-nowrap text-center">
              <b-img
                v-if="'deactivate' === colorEspecial"
                src="@/assets/images/icons/especial.png"
                class="mr-2"
                width="20px"
              />
              <b-img
                v-else
                src="@/assets/images/icons/especial-color.png"
                class="mr-2"
                width="20px"
              />
              <b-img
                v-if="'deactivate' === colorUpperCase"
                src="@/assets/images/icons/maiusculo.png"
                class="mr-2"
                width="20px"
              />
              <b-img
                v-else
                src="@/assets/images/icons/maiusculo-color.png"
                class="mr-2"
                width="20px"
              />
              <b-img
                v-if="'deactivate' === colorLowerCase"
                src="@/assets/images/icons/minusculo.png"
                class="mr-2"
                width="20px"
              />
              <b-img
                v-else
                src="@/assets/images/icons/minusculo-color.png"
                class="mr-2"
                width="20px"
              />
              <b-img
                v-if="'deactivate' === colorNumeric"
                src="@/assets/images/icons/numero.png"
                class="mr-2"
                width="20px"
              />
              <b-img
                v-else
                src="@/assets/images/icons/numero-color.png"
                class="mr-2"
                width="20px"
              />
              <b-img
                v-if="'deactivate' === colorLenght"
                src="@/assets/images/icons/tamanho.png"
                class="mr-2"
                width="20px"
              />
              <b-img
                v-else
                src="@/assets/images/icons/tamanho-color.png"
                class="mr-2"
                width="20px"
              />

              <small>
                <div class="text-center mt-2">
                  Sua senha precisa ter:
                  <div class="text-left mt-1 ml-5">
                    <li>
                      caractere especial (ex: !, @, $, etc);
                    </li>
                    <li>
                      letra maiúscula;
                    </li>
                    <li>
                      letra minúscula;
                    </li>
                    <li>
                      número;
                    </li>
                    <li>
                      no mínimo 8 caracteres.
                    </li>
                  </div>
                </div>
              </small>

              <!-- submit button -->
              <b-button
                variant="primary"
                type="submit"
                block
                class="mt-2 mb-2"
                :disabled="invalid"
                @click="validationForm"
              >
                CRIAR SENHA
              </b-button>

            </div>
          </b-form>
        </validation-observer>
      </b-card>
      <modal-message />
      <modal-invalid-token />
    </div>
  </div>
</template>

<script>
import {
  ValidationProvider, ValidationObserver, extend, localize,
} from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BCardTitle, BCardText, BInputGroup, BImg, BInputGroupAppend,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import ImageLogo from '@/layouts/components/ImageLogo.vue'
import ModalMessage from './Password-message-ok.vue'
import ModalInvalidToken from './Password-invalid-token.vue'

extend('check', value => {
  if (value !== null) {
    if (value.match('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$') != null) {
      return true
    }
  }
  return 'O campo Senha inválido'
})

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BCardText,
    ImageLogo,
    ModalMessage,
    ModalInvalidToken,
    ValidationProvider,
    ValidationObserver,
  },

  mixins: [togglePasswordVisibility],

  data() {
    return {
      token: '',
      password: '',
      confirmPassword: '',
      colorEspecial: 'deactivate',
      colorUpperCase: 'deactivate',
      colorLowerCase: 'deactivate',
      colorNumeric: 'deactivate',
      colorLenght: 'deactivate',
      valid: 0,
      // validation rules
      required,
    }
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },

  watch: {
    password() {
      if (this.password.match(/\W|_/) != null) {
        this.colorEspecial = 'active'
      } else {
        this.colorEspecial = 'deactivate'
      }

      if (this.password.match(/[A-Z]/) != null) {
        this.colorUpperCase = 'active'
      } else {
        this.colorUpperCase = 'deactivate'
      }

      if (this.password.match(/[a-z]/) != null) {
        this.colorLowerCase = 'active'
      } else {
        this.colorLowerCase = 'deactivate'
      }

      if (this.password.match(/[0-9]/) != null) {
        this.colorNumeric = 'active'
      } else {
        this.colorNumeric = 'deactivate'
      }

      if (this.password.length >= 8) {
        this.colorLenght = 'active'
      } else {
        this.colorLenght = 'deactivate'
      }
    },
  },

  mounted() {
    localize(this.$store.getters['cuido/locale'])
    this.token = this.$route.query.token
    this.tokenIsValid(this.token)
  },

  methods: {
    logout() {
    // Remove userData from localStorage
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)
    },
    validationForm() {
      this.$refs.registerName.validate().then(success => {
        if (success) {
          this.$store.commit('password/UPDATE_PASSWORD', this.password)
          this.$store.dispatch('password/savePassword', { token: this.token, password: this.password })
            .then(() => {
              this.$store.commit('password/UPDATE_SHOW_MESSAGE', true)
              this.logout()
              this.$bvModal.show('password-changed')
            })
        }
      })
    },
    tokenIsValid(token) {
      this.$store.dispatch('password/validateToken', { token })
        .then()
        .catch(() => this.$bvModal.show('invalid-token'))
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>

<style scoped>
  .card-body {
    padding-bottom: 1px;
  }
</style>
