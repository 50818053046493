<template>
  <div>
    <b-modal
      id="invalid-token"
      size="md"
      no-close-on-backdrop
      no-close-on-esc
      centered
      hide-footer
      hide-header
      hide-header-close
      class="mr-1"
    >
      <b-container class="mt-4 mb-4">
        <b-row>
          <b-col class="text-center mb-2">
            <feather-icon
              icon="AlertCircleIcon"
              size="90"
              stroke="#ea5455"
              stroke-width="1.5"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-center">
            <h4><strong>Link inválido</strong></h4>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="text-center">
            <p>O link recebido não é mais válido, pois passou do período de recuperação. Clique no botão abaixo para receber um novo link.</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-center">
            <b-button
              variant="primary"
              @click="goToPasswordReset()"
            >
              Recuperar senha
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
import {
  BModal, BContainer, BRow, BCol, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BContainer,
    BRow,
    BCol,
    BButton,
  },
  methods: {
    goToPasswordReset() {
      this.$router.push({ name: 'password-email' })
    },
  },
}
</script>
