<template>
  <div>
    <b-modal
      id="password-changed"
      size="md"
      no-close-on-backdrop
      no-close-on-esc
      centered
      hide-footer
      hide-header
      hide-header-close
    >
      <b-container class="mt-4 mb-4">
        <b-row>
          <b-col class="text-center mb-2">
            <feather-icon
              icon="CheckCircleIcon"
              size="90"
              stroke="#21d59b"
              stroke-width="1.5"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-center">
            <p>Sua senha foi atualizada com sucesso!</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-center">
            <b-button
              variant="primary"
              @click="ok()"
            >
              Ok
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
import {
  BModal, BContainer, BRow, BCol, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BContainer,
    BRow,
    BCol,
    BButton,
  },
  methods: {
    ok() {
      this.$router.push({ name: 'auth-login' })
    },
  },
}
</script>
